import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/v4-shims.scss';

import './utils/index';
import './main_section_height';

import './SendOrderToCustomer';
import './BookingItems/index';
import './users/index';
import './gallery';
import './orders/bills';
import './BootstrapAlert';
import './orders/index';
import './order_notes/index';
import './actionMessage';
import './modifications';
import './send_modification_request_modal';
import './ExportButtons/styles.scss';
import './modals/index';
import './ResendInvitation';
import './MultiEditBookings';
import './Map/index';
import './admin/index';
import './pages/file_manager_button';

import './styles/notifications_table.scss';
import './styles/modal_venues.scss';


import Home from './pages/home';
import Checkout from './pages/checkout';
import Facility from './pages/facility';
import MultiMessage from './pages/multi_message';
import Notice from './pages/notice';
import PaymentRequestCounters from './pages/payment_request_counters';
import Search from './pages/search';
import Themes from './pages/themes';
import VenueAdminCastling from './admin/users/VenueAdminCastling';


import './pages/navbar';

document.addEventListener('DOMContentLoaded', () => {
  new Checkout().start();
  new Facility().start();
  new Home().start();
  new MultiMessage().start();
  new Notice().start();
  new PaymentRequestCounters().start();
  new Search().start();
  new Themes().start();
  new VenueAdminCastling().start();
});

import { Controller } from "@hotwired/stimulus";

const RELOAD_TRIGGER = "reload-neo-table";

class NeoTableController extends Controller {
  static targets = ["filter", "sort", "table", "reload", "delete"];
  static values = { source: String, params: String };

  connect() {
    this.reloadData(this.url);
    this.reloadDataDebounced = this.debouncedSearch();
    this.element.addEventListener("action", this.handleChildAction.bind(this));
    this.element.addEventListener(RELOAD_TRIGGER, this.handleCustomEvent.bind(this));
  }

  disconnect() {
    this.element.removeEventListener(RELOAD_TRIGGER, this.handleCustomEvent.bind(this));
  }

  handleChildAction(event) {
    this.reloadDataDebounced();
  }

  handleCustomEvent(event) {
    this.reloadData(this.url);
  }

  search() {
    this.reloadDataDebounced();
  }

  get url() {
    const formData = this.hasFilterTarget ? new FormData(this.filterTarget) : new FormData();
    formData.set('neo_table', 'true');
    const params = new URLSearchParams(formData);

    const queryStringIndex = this.sourceValue.indexOf('?');
    return this.sourceValue + (queryStringIndex !== -1 ? '&' : '?') + params.toString() + '&' + this.paramsValue;
  }

  reloadData(url) {
    fetch(url, { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(() => Helper.flash_message('error', 'Something went wrong'))
  }

  sort(sortBy, sortDir) {
    let url = this.url;
    if (sortBy)  url += '&sort[column]=' + sortBy;
    if (sortDir) url += '&sort[dir]=' + sortDir;

    this.reloadData(url);
  }

  change_page(e) {
    e.preventDefault();

    const table = this.tableTarget;
    const href = e.target.getAttribute('href');
    this.reloadData(href);

    $('html, body').animate({ scrollTop: $(table).offset().top }, 250)
  }

  tableTargetConnected() {
    const table = this.tableTarget;
    const { sortBy, sortDir } = table.dataset;

    this.tableTarget.querySelectorAll('th[data-sort-by]').forEach((element) => {
      const span = document.createElement('span');
      const isCurrentSortBy = sortBy === element.dataset.sortBy;

      element.classList.add('pointer');
      span.classList.add('sorting');
      if (isCurrentSortBy) span.classList.add(sortDir);

      element.insertBefore(span, element.firstChild);

      element.addEventListener("click", () => {
        let sD = null;
        if (isCurrentSortBy) sD = sortDir === 'desc' ? 'asc' : 'desc'
        this.sort(element.dataset.sortBy, sD);
      });

      const self = this;
    });

    this.element.querySelectorAll('ul.pagination a').forEach((pageLink) => {
      pageLink.addEventListener('click', (e) => {
        e.preventDefault();
        this.change_page(e);
      })
    })
  }

  debouncedSearch() {
    return _.debounce(() => {
      const { sortBy, sortDir } = this.tableTarget.dataset;

      let url = this.url;
      if (sortBy)  url += '&sort[column]=' + sortBy;
      if (sortDir) url += '&sort[dir]=' + sortDir;

      this.reloadData(url);
    }, 250)
  }
}

export default NeoTableController;

import * as Turbo from "@hotwired/turbo";

import "lightslider/dist/js/lightslider.js";
import "lightslider/dist/css/lightslider.css";

import toastr from 'toastr';
import "toastr/build/toastr.css"

window.toastr = toastr;

import "jquery-backstretch/jquery.backstretch.min.js"

Turbo.start();
Turbo.navigator.delegate.stop()

window.Turbo = Turbo;

import '../src/pages/index'
import "../controllers"
import '../src/nextgen/Index'


// Global fix for multiple modals
$(document).on('hidden.bs.modal', function () {
  if ($('.modal.in').length > 0) {
    $('body').addClass('modal-open');
  }
});


